import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { Participant } from '../../types/v3Types';
import { isSelfPaced } from '../isSelfPaced';
import { getProgress } from './getProgress';

export function isSelfPacedCompleted(
  challenge: Challenge,
  participant: Participant,
) {
  return (
    !!challenge && isSelfPaced(challenge) && getProgress(participant) === 100
  );
}
