import { useChallengeData } from '../../../../../contexts/storage-contexts/Challenge';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { useParticipantSteps } from '../../../../../contexts/ParticipantStepsDataProvider/ParticipantStepsContext';
import utils, { getFlatStepsList } from '../../views/utils';
import { useSidebarLayoutBase } from '../../views/SidebarLayout/contexts/SidebarLayoutBase/SidebarLayoutBaseContext';

export function useStepIsUnavailable() {
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const { currentStep } = useSidebarLayoutBase();
  const { listParticipantSections } = useSections();
  const { participantSteps } = useParticipantSteps();
  const flatSteps = getFlatStepsList({
    listParticipantSections,
    participantSteps,
  });

  return (
    utils.isStepHidden(challenge, flatSteps, currentStep) ||
    utils.isVisibleStepLockedForComplete(challenge, flatSteps, currentStep)
  );
}
