import React from 'react';
import format from 'date-fns/format';
import groupBy from 'lodash/groupBy';
import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';

import { classes } from './StepsListAsTiles.st.css';
import { getRightDateFromBackend } from '../../../../../selectors/dates';

import { StepSidebar } from './StepSidebar';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { DateFnsLocales } from '../../../../../services/DateFnsLocales';
import { FCWithChildren } from '@wix/challenges-web-library';
import type { IParticipantSectionsContext } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';

export interface IScheduledStepsList {
  steps: IParticipantSectionsContext['participantSteps']['steps'];
  isSPC: boolean;
  currentStepId: string;
  onStepChosen(step: ParticipantStep): void;
}

export const ScheduledStepsList: FCWithChildren<IScheduledStepsList> = (
  props,
) => {
  const { language } = useEnvironment();
  const stepsByStartDate = groupBy(props.steps, (step) => {
    return step.dateFrame.start;
  });

  return (
    <DateFnsLocales>
      {(locales) => {
        return (
          <ul className={classes.stepsList}>
            {Object.keys(stepsByStartDate).map(
              (startDate, parentId: number) => {
                const formattedStepDate = format(
                  getRightDateFromBackend(startDate),
                  'EEEE, PP',
                  { locale: locales[language] },
                );

                return (
                  <>
                    <li
                      className={`${classes.stepsTitle}`}
                      key={`title-${parentId}`}
                    >
                      {formattedStepDate}
                    </li>

                    {stepsByStartDate[startDate].map((step, ind: number) => {
                      return (
                        <StepSidebar
                          key={`step-${parentId}-${ind}`}
                          step={step}
                          isSPC={props.isSPC}
                          currentStepId={props.currentStepId}
                          onStepChosen={props.onStepChosen}
                        />
                      );
                    })}
                  </>
                );
              },
            )}
          </ul>
        );
      }}
    </DateFnsLocales>
  );
};
