import React from 'react';

import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import {
  TextButton,
  TextButtonPriority,
  ThreeDotsLoader,
} from 'wix-ui-tpa/cssVars';
import { issueCertificate } from '@wix/ambassador-challenges-v1-participant/http';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

import { useGeneralData } from '../../contexts/GeneralDataProvider/GeneralDataContext';
import { useHttpClient } from '../../hooks/useHttpClient';
import { useToast } from '../../contexts/ToastContext/ToastContext';
import { ToastType } from '../../contexts/ToastContext/interfaces';

import { classes } from './ViewCertificate.st.css';
import { ButtonNames } from '../../contexts/main/biInterfaces';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useUser } from '../../contexts/User/UserContext';
import { useChallengeData } from '../../contexts/storage-contexts/Challenge';

export interface IViewCertificateProps {
  certificateId: string;
  programId?: string;
}

export const ViewCertificate: FCWithChildren<IViewCertificateProps> = ({
  certificateId,
  programId,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { showToast } = useToast();
  const { t } = useTranslation();
  const { instance } = useGeneralData();
  const { challengeData } = useChallengeData();
  const { user, requestMemberEmail } = useUser();
  const httpClient = useHttpClient(instance);
  const bi = useBi();

  React.useEffect(() => {
    requestMemberEmail();
  }, [requestMemberEmail]);

  const onClick = React.useCallback(async () => {
    if (!certificateId) {
      return;
    }

    bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.GetCertificate,
      }),
    );

    setLoading(true);

    try {
      await httpClient.request(
        issueCertificate({
          participantId: certificateId,
          challengeId: challengeData?.challenge?.id || programId,
        }),
      );

      showToast(
        t('live.challenges-page.modal.challenge-completed.reward.certificate', {
          participantEmail: user.email,
        }),
        { type: ToastType.success },
      );
    } catch (error) {
      showToast(t('certificates.toast.error'), {
        type: ToastType.error,
      });
      console.log('Error getting certificate: ', error);
    }

    setLoading(false);
  }, [httpClient, certificateId, user.email]);

  return (
    <TextButton
      className={classes.text}
      onClick={onClick}
      priority={TextButtonPriority.link}
    >
      {loading ? (
        <ThreeDotsLoader />
      ) : (
        t('certificates.button.view-certificate')
      )}
    </TextButton>
  );
};
