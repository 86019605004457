import React from 'react';
import { throttle } from 'lodash';

import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';
import { useTranslation, useEnvironment, useBi } from '@wix/yoshi-flow-editor';
import {
  Button as BaseButton,
  ButtonPriority as PRIORITY,
  Tooltip,
  ThumbnailImage,
} from 'wix-ui-tpa/cssVars';

import GroupIcon from '../../assets/icons/groups-new.svg';

import { st, classes } from './SocialGroup.st.css';

import { useSocialGroups } from '../../contexts/SocialGroups/SocialGroupsContext';
import { TextAlignment } from '../../components/ChallengesPage/Settings/challengeSettings/challengeSettings.types';
import settingsParams from '../../components/ChallengesPage/settingsParams';
import { SettingsKeysAbbreviation } from '../../components/ChallengesPage/Settings/constants/settingsKeysAbbreviation';
import { useSettings } from '@wix/tpa-settings/react';
import { FCWithChildren } from '@wix/challenges-web-library';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { ButtonNames } from '../../contexts/main/biInterfaces';

export type ViewMode = 'compact' | 'list' | 'card';

export interface ISocialGroup
  extends Omit<React.HTMLAttributes<HTMLElement>, 'title'> {
  Button?: FCWithChildren;
  showButton?: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  className?: string;
  mode?: ViewMode;
  alignment?: TextAlignment;
}

export const SocialGroup: FCWithChildren<ISocialGroup> = (props) => {
  const { socialGroup, isGroupsInstalled, groupPageUrl } = useSocialGroups();
  const [sectionWidth, setSectionWidth] = React.useState<number>(0);
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const alignment = props.alignment || TextAlignment.Left;
  const settings = useSettings();
  const bi = useBi();

  const { Button = BaseButton } = props;

  const showGroupImage = settings.get(
    settingsParams[SettingsKeysAbbreviation.visitorPageShowGroupImage],
  );

  React.useEffect(() => {
    let windowResize;

    if (props.mode !== 'compact') {
      windowResize = () => {
        setSectionWidth(sectionRef?.current?.offsetWidth || 0);
      };

      setSectionWidth(sectionRef?.current?.offsetWidth || 0);

      window.addEventListener('resize', throttle(windowResize, 200));
    }

    return () => window.removeEventListener('resize', windowResize);
  }, [props.mode, sectionWidth]);

  if (!socialGroup) {
    return null;
  }

  const getMode = () => {
    if (sectionWidth <= 600) {
      return 'card';
    }

    return props.mode || 'list';
  };
  const WrapTag = isGroupsInstalled && socialGroup ? 'div' : Tooltip;
  const url = `${groupPageUrl?.url}/${socialGroup?.slug}`;
  if (props.mode === 'compact') {
    return (
      <section
        ref={sectionRef}
        className={st(
          classes.root,
          {
            mode: 'compact',
            alignment,
          },
          props.className,
        )}
        style={props.style}
      >
        <WrapTag
          content={t('live-site.groups.cta-disabled.tooltip')}
          placement="top"
          appendTo="window"
        >
          <>
            <GroupIcon className={classes.groupIcon} />
            {isGroupsInstalled && socialGroup ? (
              <a
                data-hook="social-group-link"
                target="_blank"
                href={url}
                className={classes.link}
                rel="noreferrer"
                onClick={() => {
                  bi.report(
                    memberWebAppButtonClickV2({
                      buttonName: ButtonNames.GroupSmall,
                    }),
                  );
                }}
              >
                {t('live-site.groups.link-cta')}
              </a>
            ) : (
              <span className={classes.link}>
                {t('live-site.groups.link-cta')}
              </span>
            )}
          </>
        </WrapTag>
      </section>
    );
  }

  const groupToUse = socialGroup;

  return (
    <section
      data-hook="SOCIAL-GROUPS"
      ref={sectionRef}
      className={st(
        classes.root,
        {
          mode: getMode(),
          alignment,
          mobile: isMobile,
        },
        props.className,
      )}
      style={props.style}
    >
      {!!props.title && <h2 className={classes.title}>{props.title}</h2>}
      {!!props.subTitle && (
        <p className={classes.subtitle}>
          {(props.showButton && !!socialGroup && !isGroupsInstalled) ||
          (!props.showButton && !socialGroup) ||
          isGroupsInstalled
            ? props.subTitle
            : t('live-site.groups.cta-disabled.tooltip')}
        </p>
      )}

      {!!groupToUse && (
        <a
          data-hook="SOCIAL-GROUP-LINK"
          className={`${classes.group} ${
            !isGroupsInstalled && classes.notInstalled
          }`}
          onClick={(e) => {
            bi.report(
              memberWebAppButtonClickV2({
                buttonName: ButtonNames.GroupLarge,
              }),
            );

            if (
              !isGroupsInstalled ||
              (!props.showButton &&
                groupToUse.privacyLevel === PrivacyStatus.SECRET)
            ) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          target="_blank"
          rel="noreferrer"
          href={isGroupsInstalled ? url : '#'}
        >
          {!!groupToUse?.details?.logo && showGroupImage && (
            <div className={classes.media}>
              <ThumbnailImage
                className={classes.mediaItem}
                fluid={true}
                forceImageContain={true}
                src={groupToUse.details.logo?.mediaId}
                alt={groupToUse.title}
              />
            </div>
          )}
          <div className={classes.description}>
            <div>
              <h3 className={classes.groupTitle}>{groupToUse.title}</h3>
              <div className={classes.groupInfo}>
                <span className={classes.privacy}>
                  {t(
                    `live-site.groups.privacy.${groupToUse.privacyLevel.toLocaleLowerCase()}`,
                  )}
                </span>
                <span className={classes.memberCount}>
                  {' '}
                  &bull;{' '}
                  {t(`live-site.groups.members-count_icu`, {
                    count: groupToUse.membersCount,
                  })}
                </span>
              </div>
            </div>

            {props.showButton && (
              <WrapTag
                content={t('live-site.groups.cta-disabled.tooltip')}
                placement="top"
                appendTo="window"
              >
                <Button
                  data-hook="SOCIAL-GROUP-BUTTON"
                  disabled={!isGroupsInstalled}
                  fullWidth={isMobile}
                  priority={PRIORITY.primary}
                  className={classes.groupButtonLayout}
                >
                  {t('live-site.groups.cta')}
                </Button>
              </WrapTag>
            )}
          </div>
        </a>
      )}
    </section>
  );
};

SocialGroup.displayName = 'SocialGroup';
