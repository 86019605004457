import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/cssVars';
import { Box } from '../../Box';
import { ALIGNMENT, AwardsBadges } from '../../Badges';
import { useChallengeData } from '../../../contexts/storage-contexts/Challenge';
import { useUser } from '../../../contexts/User/UserContext';
import { getEarnedRewards } from '../../../selectors/rewards/getEarnedRewards';
import {
  getBadgesTrigger,
  getCertificateTrigger,
} from '../../../selectors/rewards/rewardsSelectors';
import { FCWithChildren } from '@wix/challenges-web-library';
import { classes } from '../ChallengeCompletedModal.st.css';

export const RewardsSection: FCWithChildren<{}> = () => {
  const { t } = useTranslation();
  const { challengeData } = useChallengeData();
  const { participant, requestMemberEmail, user } = useUser();
  const { rewards } = getEarnedRewards(
    challengeData?.challenge?.settings?.rewards,
    participant,
  );
  const badgeTrigger = getBadgesTrigger(rewards);
  const certificateTrigger = getCertificateTrigger(rewards);

  React.useEffect(() => {
    requestMemberEmail();
  }, [requestMemberEmail]);

  if (!rewards.length) {
    return null;
  }

  return (
    <section>
      {certificateTrigger && (
        <Text className={classes.text}>
          {t(
            'live.challenges-page.modal.challenge-completed.reward.certificate',
            {
              participantEmail: user.email,
            },
          )}
        </Text>
      )}

      {badgeTrigger && (
        <>
          <Box justifyContent="center" marginTop={2}>
            <Text className={classes.text}>
              {t('live.challenges-page.modal.challenge-completed.reward')}
            </Text>
          </Box>

          <Box marginTop={2} justifyContent="center">
            <AwardsBadges
              removeCustomBottomMargin={true}
              alignment={ALIGNMENT.center}
            />
          </Box>
        </>
      )}
    </section>
  );
};
